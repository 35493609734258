import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    DateInput,
} from 'react-admin';

const redirect = (basePath, id, data) => `/proyectos/${data.proyectoId}/show/ingresos`;

const IngresosCreate = props => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <ReferenceInput source="proyectoId" reference="proyectos" validate={[required()]}>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput source="proyectoAsignacionPresupuestaria" reference="proyectos" validate={[required()]} >
                <AutocompleteInput optionText="nombre" helperText="resources.ingresos.fieldsHelper.proyectoAsignacionPresupuestaria"/>
            </ReferenceInput>
            <DateInput source="fechaContrato" validate={[required()]} helperText="resources.ingresos.fieldsHelper.fechaContrato" />
            <TextInput source="nombre" validate={[required()]}/>
            <NumberInput source="montoPesos" />
            <NumberInput source="montoDolares" />
        </SimpleForm>
    </Create>
);

export default IngresosCreate;
