import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    DateInput,
} from 'react-admin';

import EditFormButtons from '../../../libs/sgx/components/EditFormButtons';

import DolarAutocompleteInput from '../../dolar/DolarAutocompleteInput';

const redirect = ({record}) => `/proyectos/${record.proyectoId}/show/ingresos`;

const IngresosEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditFormButtons redirectOnSuccess={redirect} />}>
            <ReferenceInput source="proyectoId" reference="proyectos" validate={[required()]}>
                <AutocompleteInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput source="proyectoAsignacionPresupuestaria" reference="proyectos" validate={[required()]}>
                <AutocompleteInput optionText="nombre" helperText="resources.ingresos.fieldsHelper.proyectoAsignacionPresupuestaria" />
            </ReferenceInput>
            <DateInput source="fechaContrato" validate={[required()]} helperText="resources.ingresos.fieldsHelper.fechaContrato" />
            <ReferenceInput source="fechaEmision" reference="dolar" sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <DolarAutocompleteInput helperText="resources.ingresos.fieldsHelper.fechaEmision" />
            </ReferenceInput>
            <ReferenceInput source="fechaCobro" reference="dolar" sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <DolarAutocompleteInput helperText="resources.ingresos.fieldsHelper.fechaCobro" />
            </ReferenceInput>
            <TextInput source="nombre" validate={[required()]}/>
            <NumberInput source="montoPesos" />
            <NumberInput source="montoDolares" />
        </SimpleForm>
    </Edit>
);
export default IngresosEdit;
