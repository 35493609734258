import React from 'react';
import {
    Show,
    Pagination,
    ReferenceManyField,
    ReferenceField,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    TabbedShowLayoutTabs,
    SimpleList,
    Datagrid,
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import { DATE_OPTIONS } from '../../../libs/sgx/shared/date';
import { dateFormatterParams, formatDistance, isDatePast } from '../../../libs/sgx/shared/date';
import { currencyFormatter, Divisa } from '../../../libs/sgx/shared/currency';

import ShowFormTitle from '../../../libs/sgx/components/ShowFormTitle';

import CreateRelatedButton from '../../components/CreateRelatedButton';
import IngresosPorMesBarChart from './IngresosPorMesBarChart';

import { customersPermissions } from '../../customers/index';
import ProyectoMovimientos from './ProyectoMovimientos';
import PersonaField from '../../personas/PersonaField';
import {
    PAYROLL_ROLES,
} from '../../roles-set';

const useTabsStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: '100vw',
    },
}));

const IngresoActionIcon = ({record: {fechaContrato, fechaEmision, fechaCobro}}) => {
    if (fechaCobro) {
        return isDatePast(fechaCobro) ? <DoneAllIcon color="primary"/> : <DoneAllIcon color="primary" style={{ opacity: 0.5 }}/>;
    }
    if (fechaEmision) {
        return isDatePast(fechaEmision) ? <DoneAllIcon /> : <DoneAllIcon style={{ opacity: 0.5 }}/>;
    }
    if (fechaContrato) {
        return isDatePast(fechaContrato) ? <DoneIcon color="action" /> : <DoneIcon color="action" style={{ opacity: 0.5 }} />;
    }
    return <BlockIcon color="disabled" />
};

const signoOtroProyecto = ({proyectoId, proyectoAsignacionPresupuestaria}) => proyectoId===proyectoAsignacionPresupuestaria ? '' : ' 💸';

const ingresoSecondaryText = ({fechaContrato, fechaEmision, fechaCobro}) => {
    if (fechaCobro) return `Se cobró ${formatDistance(fechaCobro)}`;
    if (fechaEmision) return `Emitida ${formatDistance(fechaEmision)}`;
    if (fechaContrato) return `Facturable ${formatDistance(fechaContrato)}`;
    return 'Sin fechas';
};

const ProyectoShow = props => {
    const classes = useTabsStyles();
    const accesoACustomers = props.permissions?.hasAnyAssignment(...customersPermissions);
    const accesoAIngresos = props.permissions?.hasAnyAssignment(...PAYROLL_ROLES);
    return (
    <Show {...props} title={<ShowFormTitle mapper={(record)=> record.nombre}/>}>
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" className={classes.root} />}>
            <Tab label="resources.proyectos.tabs.grafico" >
                {accesoAIngresos && <ProyectoMovimientos source="id"/>}
                <Divider />
                <ReferenceField source="legalEntityId" reference="legal-entities" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                {accesoACustomers && <ReferenceField source="customerId" reference="customers" link={false}>
                    <TextField source="name" />
                </ReferenceField>}
            </Tab>
            <Tab label="resources.proyectos.tabs.general" path="general">
                <TextField source="nombre" />
                <ReferenceField source="businessUnitId" reference="business-units" link={false}>
                    <TextField source="name" />
                </ReferenceField>                

                <DateField source="fechaInicio" {...dateFormatterParams} />
                <DateField source="fechaFin" {...dateFormatterParams} />
            </Tab>
            {accesoAIngresos && <Tab label="resources.proyectos.tabs.ingresos" path="ingresos">
                <CreateRelatedButton
                    reference="ingresos"
                    refFieldName="proyectoId"
                    label="resources.proyectos.action.createIngreso"
                />
                <ReferenceManyField
                    addLabel={false}
                    reference="ingresos"
                    target="proyectoId"
                    sort={{ field: 'fechaContrato', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <SimpleList
                        leftIcon={record => <IngresoActionIcon record={record}/>}
                        primaryText={record => record?.nombre}
                        secondaryText={record => ingresoSecondaryText(record)}
                        tertiaryText={record => currencyFormatter(Divisa.PESO)(record.montoPesos) + ' ' +currencyFormatter(Divisa.DOLAR)(record.montoDolares) + signoOtroProyecto(record)}
                        linkType="edit"
                    />
                </ReferenceManyField>
            </Tab>}
            {accesoAIngresos && <Tab label="resources.proyectos.tabs.otrosIngresos" path="otrosIngresos">
                <IngresosPorMesBarChart />
                <ReferenceManyField
                    addLabel={false}
                    reference="ingresos"
                    target="proyectoAsignacionPresupuestaria"
                    sort={{ field: 'fechaContrato', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <SimpleList
                        leftIcon={record => <IngresoActionIcon record={record}/>}
                        primaryText={record => <>
                            <span>{record.nombre}</span>
                            <span style={{opacity: 0.6, fontSize: '0.7em'}}> {ingresoSecondaryText(record)}</span>
                        </>}
                        secondaryText={record => <ReferenceField record={record} source="proyectoId" reference="proyectos" link={false}>
                        <TextField source="nombre" />
                    </ReferenceField>}
                        tertiaryText={record => currencyFormatter(Divisa.PESO)(record.montoPesos) + ' ' + currencyFormatter(Divisa.DOLAR)(record.montoDolares)}
                        linkType="edit"
                    />
                </ReferenceManyField>
            </Tab>}
            <Tab label="resources.proyectos.tabs.asignaciones" path="asignaciones">
                <ReferenceManyField
                    addLabel={false}
                    reference="asignaciones"
                    target="proyectoId"
                    sort={{ field: 'fechaInicio', order: 'DESC' }}
                    pagination={<Pagination />}
                >
                    <Datagrid rowClick="show">
                        <ReferenceField source="personaId" reference="personas" link="show">
                            <PersonaField />
                        </ReferenceField>
                        <ReferenceField source="jobId" reference="jobs" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="horasMensuales" />
                        <DateField source="fechaInicio" locales="es-AR" options={DATE_OPTIONS}/>
                        <DateField source="fechaFin" locales="es-AR" options={DATE_OPTIONS}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)};
export default ProyectoShow;